import * as validators from '@vuelidate/validators'

export const useI18nValidators = () => {
    const { $i18n } = useNuxtApp();
    const { createI18nMessage } = validators
    const messagePath = ({ $validator }: { $validator: string}) => `forms.validations.${$validator}`;

    const withI18nMessage = createI18nMessage({ t: $i18n.t.bind($i18n), messagePath });

    const required = withI18nMessage(validators.required);
    const email = withI18nMessage(validators.email);
    const numeric = withI18nMessage(validators.numeric);
    const minLength = withI18nMessage(validators.minLength, { withArguments: true })
    const maxLength = withI18nMessage(validators.maxLength, { withArguments: true })
    const onlyLetters = withI18nMessage(validators.helpers.regex(/^[a-zA-Zа-яА-Я-]*$/));

    return {
        required,
        email,
        numeric,
        minLength,
        maxLength,
        onlyLetters
    }
};
